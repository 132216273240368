import React, { Fragment, useState } from "react"
import {
  LegacyCard,
  FormLayout,
  TextField,
  Icon,
  LegacyStack,
  Button,
  ButtonGroup,
  TextContainer,
  RangeSlider,
  Text,
} from "@shopify/polaris"
import {
  TextAlignLeftIcon,
  TextAlignCenterIcon,
  TextAlignRightIcon,
  TextFontIcon,
  DeleteIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@shopify/polaris-icons"
import MultiProductsSetting from "./multiProductsSetting"
import update from "immutability-helper"
import styled from "styled-components"
import FieldTitle from "../../../../components/fieldTitle"
import CardDivider from "../../../../components/cardDivider"
const LabelWrapperWrapper = styled.span`
  font-size: 1.5rem;
  text-transform: capitalize;
`
const TextSizeWrapper = styled.div`
  .Polaris-ButtonGroup__Item:first-child svg {
    width: 130%;
    height: 130%;
    max-width: 130%;
    max-height: 130%;
    margin-top: -5px;
  }
  .Polaris-ButtonGroup__Item:last-child .Polaris-Icon {
    padding-top: 3px;
    svg {
      margin-left: 3px;
      width: 80%;
      height: 80%;
      max-width: 80%;
      max-height: 80%;
    }
  }
`
function ProductEdit(props) {
    const {
        offer,
        index,
        host,
        shop,
        state,
        setState,
        productPickerShow,
        productEdit,
        setProductEdit,
        setDownsellEdit,
        currency,
        currencySymbol = "$",
        checkoutOfferType,
        token,
        updatePreview,
        error,
        setError,
      } = props
    const [customizationEdit, setCustomizationEdit] = useState(-1)
    const addAnotherProduct = () => {
        setState(
          update(state, {
            product: {
              [index]: {
                products: {
                  $push: [{ discount: { sym: currencySymbol, value: 0 } }],
                },
              },
            },
          })
        )
        setProductEdit([index, state.product[index].products.length])
      }
    
      const updateBannerTextAlign = (val) => {
        setState(
          update(state, {
            product: {
              [index]: { bannerTextAlign: { $set: val } },
            },
          })
        )
        updatePreview()
      }
      const updateBannerTextSize = (val) => {
        setState(
          update(state, {
            product: {
              [index]: { bannerTextSize: { $set: val } },
            },
          })
        )
        updatePreview()
      }
      const updatePriceTextAlign = (val) => {
        setState(
          update(state, {
            product: {
              [index]: { priceTextAlign: { $set: val } },
            },
          })
        )
        updatePreview()
      }
      const updatePriceTextSize = (val) => {
        setState(
          update(state, {
            product: {
              [index]: { priceTextSize: { $set: val } },
            },
          })
        )
        updatePreview()
      }
      const copyTranslations = (index) => {
        setState(
          update(state, {
            product: {
              [index]: {
                timerText: { $set: state.product[0].timerText },
                savingText: { $set: state.product[0].savingText },
                shippingText: { $set: state.product[0].shippingText },
                quantityText: { $set: state.product[0].quantityText },
                subtotalText: { $set: state.product[0].subtotalText },
                totalText: { $set: state.product[0].totalText },
                taxesText: { $set: state.product[0].taxesText },
                addButtonText: { $set: state.product[0].addButtonText },
                removeButtonText: { $set: state.product[0].removeButtonText },
                acceptButtonText: { $set: state.product[0].acceptButtonText },
                declineButtonText: { $set: state.product[0].declineButtonText },
              },
            },
          })
        )
      }
  return (
    <>
          {checkoutOfferType === "manual" && (
            <Fragment>
              <MultiProductsSetting
                token={token}
                shop={shop}
                host={host}
                state={state}
                setState={setState}
                productPickerShow={productPickerShow}
                products={offer.products}
                index={index}
                currency={currency}
                currencySymbol={currencySymbol}
                productEdit={productEdit}
                setProductEdit={setProductEdit}
                error={error}
                setError={setError}
              />
              <div style={{ margin: "20px 0" }}>
                <Button onClick={addAnotherProduct} variant="primary">
                  + Add another product
                </Button>
              </div>
            </Fragment>
          )}

          {customizationEdit !== index ? (
            <div
              onClick={() => {
                setCustomizationEdit(index)
                updatePreview()
              }}
              className="customization"
            >
              <LegacyCard sectioned>
                <LegacyStack alignment="center">
                  <LegacyStack.Item fill>
                    <Text variant="headingMd" as="h2">
                      Customization
                    </Text>
                    <Text variant="bodyMd" as="span" tone="subdued">
                      Layout, callout banner, timer, translations
                    </Text>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <Icon source={ChevronDownIcon} />
                  </LegacyStack.Item>
                </LegacyStack>
              </LegacyCard>
            </div>
          ) : (
            <LegacyCard sectioned>
              <div
                onClick={() => setCustomizationEdit(-1)}
                style={{ paddingBottom: "10px" }}
              >
                <LegacyStack alignment="center">
                  <LegacyStack.Item fill>
                    <Text variant="headingMd" as="h2">
                      Customization
                    </Text>
                  </LegacyStack.Item>
                  <LegacyStack.Item>
                    <Icon source={ChevronUpIcon} />
                  </LegacyStack.Item>
                </LegacyStack>
              </div>
              <FormLayout>
                <FieldTitle title="Layout" />
                <RangeSlider
                  label="Products per row"
                  min={1}
                  max={3}
                  suffix={
                    <p>{offer.productsPerRow ? offer.productsPerRow : 1}</p>
                  }
                  value={offer.productsPerRow ? offer.productsPerRow : 1}
                  onChange={(val) => {
                    setState(
                      update(state, {
                        product: {
                          [index]: { productsPerRow: { $set: val } },
                        },
                      })
                    )
                    updatePreview()
                  }}
                  output
                />
                {checkoutOfferType !== "manual" && (
                  <RangeSlider
                    label="Set maximum number of products to show:"
                    min={1}
                    max={10}
                    suffix={
                      <p>{offer.productNumber ? offer.productNumber : 1}</p>
                    }
                    value={offer.productNumber ? offer.productNumber : 1}
                    onChange={(val) => {
                      setState(
                        update(state, {
                          product: {
                            [index]: { productNumber: { $set: val } },
                          },
                        })
                      )
                      updatePreview()
                    }}
                    output
                  />
                )}
                <CardDivider />
                <FieldTitle title="Callout banner" />
                <LegacyStack>
                  <ButtonGroup variant="segmented">
                    <Button
                      pressed={offer.bannerBold}
                      onClick={() => {
                        setState(
                          update(state, {
                            product: {
                              [index]: {
                                bannerBold: { $set: !offer.bannerBold },
                              },
                            },
                          })
                        )
                        updatePreview()
                      }}
                    >
                      B
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup variant="segmented">
                    <Button
                      pressed={"left" === offer.bannerTextAlign}
                      onClick={() => updateBannerTextAlign("left")}
                    >
                      <Icon source={TextAlignLeftIcon} tone="base" />
                    </Button>
                    <Button
                      pressed={"center" === offer.bannerTextAlign}
                      onClick={() => updateBannerTextAlign("center")}
                    >
                      <Icon source={TextAlignCenterIcon} tone="base" />
                    </Button>
                    <Button
                      pressed={"right" === offer.bannerTextAlign}
                      onClick={() => updateBannerTextAlign("right")}
                    >
                      <Icon source={TextAlignRightIcon} tone="base" />
                    </Button>
                  </ButtonGroup>
                  <TextSizeWrapper>
                    <ButtonGroup variant="segmented">
                      <Button
                        pressed={"2.4rem" === offer.bannerTextSize}
                        onClick={() => updateBannerTextSize("2.4rem")}
                      >
                        <Icon source={TextFontIcon} tone="base" />
                      </Button>
                      <Button
                        pressed={"1.6rem" === offer.bannerTextSize}
                        onClick={() => updateBannerTextSize("1.6rem")}
                      >
                        <Icon source={TextFontIcon} tone="base" />
                      </Button>
                      <Button
                        pressed={"1.2rem" === offer.bannerTextSize}
                        onClick={() => updateBannerTextSize("1.2rem")}
                      >
                        <Icon source={TextFontIcon} tone="base" />
                      </Button>
                    </ButtonGroup>
                  </TextSizeWrapper>
                </LegacyStack>
                <TextField
                  label="Callout banner"
                  placeholder="It’s not too late to add to your order. Buy one more at 20% off. "
                  onChange={(val) => {
                    setState(
                      update(state, {
                        product: { [index]: { banner: { $set: val } } },
                      })
                    )
                  }}
                  value={offer.banner}
                  multiline={4}
                  onFocus={() => updatePreview()}
                  labelHidden
                />
                <CardDivider />
                <FieldTitle title="Price" />
                <LegacyStack>
                  <ButtonGroup variant="segmented">
                    <Button
                      pressed={offer.priceTextBold}
                      onClick={() => {
                        setState(
                          update(state, {
                            product: {
                              [index]: {
                                priceTextBold: { $set: !offer.priceTextBold },
                              },
                            },
                          })
                        )
                        updatePreview()
                      }}
                    >
                      B
                    </Button>
                  </ButtonGroup>
                  <ButtonGroup variant="segmented">
                    <Button
                      pressed={"left" === offer.priceTextAlign}
                      onClick={() => updatePriceTextAlign("left")}
                    >
                      <Icon source={TextAlignLeftIcon} tone="base" />
                    </Button>
                    <Button
                      pressed={"center" === offer.priceTextAlign}
                      onClick={() => updatePriceTextAlign("center")}
                    >
                      <Icon source={TextAlignCenterIcon} tone="base" />
                    </Button>
                    <Button
                      pressed={"right" === offer.priceTextAlign}
                      onClick={() => updatePriceTextAlign("right")}
                    >
                      <Icon source={TextAlignRightIcon} tone="base" />
                    </Button>
                  </ButtonGroup>
                  <TextSizeWrapper>
                    <ButtonGroup variant="segmented">
                      <Button
                        pressed={"2.4rem" === offer.priceTextSize}
                        onClick={() => updatePriceTextSize("2.4rem")}
                      >
                        <Icon source={TextFontIcon} tone="base" />
                      </Button>
                      <Button
                        pressed={"1.6rem" === offer.priceTextSize}
                        onClick={() => updatePriceTextSize("1.6rem")}
                      >
                        <Icon source={TextFontIcon} tone="base" />
                      </Button>
                      <Button
                        pressed={"1.2rem" === offer.priceTextSize}
                        onClick={() => updatePriceTextSize("1.2rem")}
                      >
                        <Icon source={TextFontIcon} tone="base" />
                      </Button>
                    </ButtonGroup>
                  </TextSizeWrapper>
                </LegacyStack>
                <CardDivider />
                <TextField
                  label={<FieldTitle title="Offer expiration" />}
                  prefix="This offer expires in (minutes)"
                  type="number"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: { [index]: { expiration: { $set: val } } },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={
                    offer?.expiration ? offer?.expiration.toString() : "15"
                  }
                />
                <CardDivider />
                <LegacyStack alignment="center">
                  <LegacyStack.Item fill>
                    <TextContainer spacing="tight">
                      <LabelWrapperWrapper>
                        <Text variant="bodyMd" as="span" fontWeight="semibold">
                          Translations
                        </Text>
                      </LabelWrapperWrapper>
                    </TextContainer>
                  </LegacyStack.Item>
                  {index > 0 && (
                    <LegacyStack.Item>
                      <Button
                        onClick={() => copyTranslations(index)}
                        variant="plain"
                      >
                        Copy translations from Page 1
                      </Button>
                    </LegacyStack.Item>
                  )}
                </LegacyStack>
                <LegacyCard sectioned>
                <FormLayout>
                <FieldTitle title="Timer Text" />
                <TextField
                  label="Timer text"
                  placeholder="This exclusive offer expires in"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: { [index]: { timerText: { $set: val } } },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.timerText}
                />
                <CardDivider />
                <FieldTitle title="Product Button Text" />
                <TextField
                  label="Add product button text"
                  placeholder="Add"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: { [index]: { addButtonText: { $set: val } } },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.addButtonText}
                />
                <TextField
                  label="Remove product button text"
                  placeholder="Remove"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: {
                          [index]: { removeButtonText: { $set: val } },
                        },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.removeButtonText}
                />
                <CardDivider />
                <FieldTitle title="Accept & Decline Button Text" />
                <TextField
                  label="Accept button text"
                  placeholder="Pay now"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: {
                          [index]: { acceptButtonText: { $set: val } },
                        },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.acceptButtonText}
                />
                <TextField
                  label="Decline button text"
                  placeholder="Decline this offer"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: {
                          [index]: { declineButtonText: { $set: val } },
                        },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.declineButtonText}
                />
                <CardDivider />
                <FieldTitle title="Subtotal & Shipping Text" />
                <TextField
                  label="Subtotal text"
                  placeholder="Subtotal"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: { [index]: { subtotalText: { $set: val } } },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.subtotalText}
                />
                <TextField
                  label="Shipping text"
                  placeholder="Shipping"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: { [index]: { shippingText: { $set: val } } },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.shippingText}
                />
                <CardDivider />
                <FieldTitle title="Other Text" />
                <TextField
                  label="Saving text"
                  placeholder="Saving"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: { [index]: { savingText: { $set: val } } },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.savingText}
                />
                
                <TextField
                  label="Quantity text"
                  placeholder="Quantity"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: { [index]: { quantityText: { $set: val } } },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.quantityText}
                />
                <TextField
                  label="Taxes text"
                  placeholder="Taxes"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: { [index]: { taxesText: { $set: val } } },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.taxesText}
                />
                <TextField
                  label="Total text"
                  placeholder="Total"
                  onChange={(val) =>
                    setState(
                      update(state, {
                        product: { [index]: { totalText: { $set: val } } },
                      })
                    )
                  }
                  onFocus={() => updatePreview()}
                  value={offer.totalText}
                />
                
                </FormLayout>
                </LegacyCard>
              </FormLayout>
            </LegacyCard>
          )}
          </>
  )
}
export default ProductEdit